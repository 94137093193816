import {StyleExtractor, isNumber} from "@kubric/utils";
import {graphql} from "gatsby";
import React from "react";

import Layout from "../../components/Layout";
import StructuredData, {
  StructuredDataType,
} from "../../components/StructuredData";
import Integrations from "../../components/commons/Integrations";
import * as styles from "./styles.module.scss";

const IntegrationsPage = ({data, theme}) => {
  const seoData = data.allSanitySeoMeta.nodes[0];

  let {search} =
    typeof window === "undefined" ? {search: "?i=0"} : window.location;
  let [, roleIndex] = search.match(/&?i=(\d*)&?/);
  roleIndex = isNumber(roleIndex) ? +roleIndex : 0;
  const styler = new StyleExtractor(styles, theme);
  return (
    <Layout seoData={seoData}>
      <StructuredData
        type={StructuredDataType.BREADCRUMB}
        data={{
          name: "Integrations | Mason",
          items: [
            {
              name: "Integrations | Mason",
              item: "https://getmason.io/integrations",
            },
          ],
        }}
      />
      <div className={styler.get("container")}>
        <Integrations roleIndex={roleIndex} showCTA />
      </div>
    </Layout>
  );
};

export default IntegrationsPage;

export const query = graphql`
  query {
    allSanitySeoMeta(filter: {page: {eq: "integrations"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
  }
`;
